<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="450px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          class="d-flex flex-column align-center justify-center"
          style="height: 230px"
        >
          <div v-if="temporary">
            <div class="d-flex justify-center mb-2">
              <img src="../../../assets/img/temporarysave.svg" height="50px" alt="" />
            </div>
            <p class="color_default text-center">
              <b>Data berhasil disimpan</b>
            </p>
            <div class="px-5">
              <p class="small_txt color_txt text-center">
                Data yang telah disimpan akan langsung memasuki tahap
                konfirmasi. <br />
                Anda dapat melanjutkan pengisian atau melakukan perbaikan jika
                terdapat data yang tidak sesuai
              </p>
            </div>
          </div>
          <div v-if="!temporary">
            <div class="px-5">
              <p class="small_txt color_txt text-center">
                Setelah mengirim, pengisian form pada periode waktu ini tidak
                dapat diisikan kembali
              </p>
              <p class="small_txt color_txt text-center">Lanjutkan tindakan?</p>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                class="text-capitalize py-6 mr-4"
                @click="$emit('save')"
                dark
                color="#00b4cc"
                width="100px"
                depressed
                >Ya</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="$emit('close')"
                outlined
                color="#00b4cc"
                width="100px"
                depressed
                >Batal</v-btn
              >
            </div>
          </div>
          <div style="height: 20px"></div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            ></v-progress-circular>
            <b class="color_default">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
// import Swal from "sweetalert2";

export default {
  name: "alertFormulir",
  props: ["dialogAlert", "temporary", "body"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
    };
  },
  methods: {
    
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>