import { render, staticRenderFns } from "./successAlert.vue?vue&type=template&id=255f0f4d&scoped=true"
import script from "./successAlert.vue?vue&type=script&lang=js"
export * from "./successAlert.vue?vue&type=script&lang=js"
import style0 from "./successAlert.vue?vue&type=style&index=0&id=255f0f4d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255f0f4d",
  null
  
)

export default component.exports