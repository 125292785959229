<template>
  <v-row justify="start">
    <v-dialog v-model="dialogSuccess" fullscreen>
      <div class="temp_scs">
        <v-icon size="200">mdi-account-check-outline</v-icon>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
// import Swal from "sweetalert2";

export default {
  name: "alertFormulir",
  props: ["dialogSuccess", "wait"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
    }),
  },
  data() {
    return {
      role: "",
    };
  },
  methods: {},
};
</script>

<style scoped>
.temp_scs {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>